import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dfba3b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "spin"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["value", "onBlur"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          ($setup.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_ion_spinner)
              ]))
            : (_openBlock(), _createBlock(_component_ion_grid, {
                key: 1,
                fixed: ""
              }, {
                default: _withCtx(() => [
                  (Object.keys($setup.groupedCartItems).length > 0)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.groupedCartItems, (value, merchantId) => {
                          return (_openBlock(), _createBlock(_component_ion_list, { key: merchantId }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, {
                                lines: "none",
                                "router-link": {
                name: 'MerchantDetailPage',
                params: { id: value.merchant.id }
              },
                                button: "",
                                detail: ""
                              }, {
                                default: _withCtx(() => [
                                  (value.merchant.logo)
                                    ? (_openBlock(), _createBlock(_component_ion_avatar, {
                                        key: 0,
                                        slot: "start"
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("img", {
                                            src: value.merchant.logoLink
                                          }, null, 8, _hoisted_3)
                                        ]),
                                        _: 2
                                      }, 1024))
                                    : _createCommentVNode("", true),
                                  _createVNode(_component_ion_label, { color: "primary" }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h2", null, [
                                        _createElementVNode("b", null, _toDisplayString(value.merchant.name), 1)
                                      ])
                                    ]),
                                    _: 2
                                  }, 1024)
                                ]),
                                _: 2
                              }, 1032, ["router-link"]),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.cartItems, (item) => {
                                return (_openBlock(), _createBlock(_component_ion_item, {
                                  lines: "full",
                                  key: item.productId
                                }, {
                                  default: _withCtx(() => [
                                    (item.productImage)
                                      ? (_openBlock(), _createBlock(_component_ion_thumbnail, {
                                          key: 0,
                                          slot: "start",
                                          "router-link": `/products/${item.productId}`
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("img", {
                                              src: $setup.addResizeUrlParams(item.productImage, 300)
                                            }, null, 8, _hoisted_4)
                                          ]),
                                          _: 2
                                        }, 1032, ["router-link"]))
                                      : _createCommentVNode("", true),
                                    _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("h3", null, [
                                          _createElementVNode("b", null, _toDisplayString(item.productName), 1),
                                          ($setup.getProductMinOrderQty(item.productId) > 0)
                                            ? (_openBlock(), _createElementBlock("span", _hoisted_5, " (最少訂購數量: " + _toDisplayString($setup.getProductMinOrderQty(item.productId)) + ")", 1))
                                            : _createCommentVNode("", true)
                                        ]),
                                        _createElementVNode("p", null, _toDisplayString($setup.t('CartPage.lineTotal')) + "HK$" + _toDisplayString(Number(item.unitPrice * item.quantity).toLocaleString()), 1),
                                        _createVNode(_component_ion_item, {
                                          class: "qty-input-item ion-no-padding",
                                          lines: "none"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_ion_button, {
                                              class: "qty-action-btn",
                                              onClick: ($event: any) => ($setup.decrementCartItemQty(item))
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, {
                                                  slot: "icon-only",
                                                  icon: $setup.remove
                                                }, null, 8, ["icon"])
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]),
                                            _createElementVNode("input", {
                                              class: "quantity-input",
                                              type: "number",
                                              min: "0",
                                              max: "9999",
                                              step: "1",
                                              value: item.quantity,
                                              onBlur: ($event: any) => ($setup.onFinishInputCartItemQty(item, $event)),
                                              placeholder: "0"
                                            }, null, 40, _hoisted_6),
                                            _createVNode(_component_ion_button, {
                                              class: "qty-action-btn",
                                              onClick: ($event: any) => ($setup.incrementCartItemQty(item))
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_icon, {
                                                  slot: "icon-only",
                                                  icon: $setup.add
                                                }, null, 8, ["icon"])
                                              ]),
                                              _: 2
                                            }, 1032, ["onClick"]),
                                            _createVNode(_component_ion_buttons, { slot: "end" }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_ion_button, {
                                                  slot: "icon-only",
                                                  onClick: ($event: any) => ($setup.openCartItemModal(item))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, { icon: $setup.createOutline }, null, 8, ["icon"])
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"]),
                                                _createVNode(_component_ion_button, {
                                                  slot: "icon-only",
                                                  onClick: ($event: any) => ($setup.removeCartItem(item))
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_ion_icon, { icon: $setup.trashOutline }, null, 8, ["icon"])
                                                  ]),
                                                  _: 2
                                                }, 1032, ["onClick"])
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _withDirectives(_createVNode(_component_ion_badge, {
                                      class: "top-right-badge",
                                      onClick: ($event: any) => ($setup.openCartItemModal(item)),
                                      color: "tertiary"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString($setup.t('customizedProduct')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["onClick"]), [
                                      [_vShow, item.isCustomized]
                                    ])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 2
                          }, 1024))
                        }), 128)),
                        _createVNode(_component_ion_item, { lines: "none" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, null, {
                              default: _withCtx(() => [
                                _createElementVNode("b", null, _toDisplayString($setup.t('CartPage.orderTotal')) + "HK$" + _toDisplayString($setup.getCartTotal().toLocaleString()), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          expand: "block",
                          fill: "outline",
                          "router-link": {
            name: 'CheckoutPage',
          }
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('CartPage.checkout')), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_ion_list, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-text-center" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString($setup.t('CartPage.emptyCart')), 1)
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_ion_button, {
                          expand: "block",
                          "router-link": "/products"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString($setup.t('CartPage.shopNow')), 1)
                          ]),
                          _: 1
                        })
                      ]))
                ]),
                _: 1
              }))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}