
// Vue reactivity
import { computed } from "vue";

// icons
import { add, remove, createOutline, trashOutline, searchOutline, notificationsOutline, } from "ionicons/icons";

// components
import {  IonPage, IonContent, IonGrid, IonButtons, IonButton, IonIcon, IonNote,
          IonSpinner, IonList, IonItem, IonThumbnail, IonLabel, IonAvatar, IonBadge,
          alertController, modalController, } from "@ionic/vue";

import PageHeader from "@/components/PageHeader.vue";
import CartItemModal from '@/components/modals/CartItemModal.vue';

// API services
import CartService from '@/services/CartService';

import { utils } from '@/composables/utils';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: "CartPage",
  components: {
    IonPage, IonContent, IonGrid, IonButtons, IonButton, IonIcon, IonNote, IonBadge,
    IonSpinner, IonList, IonItem, IonThumbnail, IonLabel, IonAvatar, PageHeader,
  },
  setup() {
    const store = useStore();

    // 1. declare state variables (ref to make them reactive)
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingUser);
    const groupedCartItems = computed(() => store.getters.getGroupedCartItems); // cart items grouped by merchants
    const cartItems = computed(() => store.state.cartItems);
    const getProductKeyVal = (productId, key) => {
      const product = store.getters.getProductById(productId);
      return product ? product[key] : "";
    }
    const getProductMinOrderQty = (productId) => (getProductKeyVal(productId, 'moq'));

    let serverReqFlag: any = null; // setTimeout

    // methods or filters
    const { t } = useI18n();
    const { formatDate, addResizeUrlParams } = utils();
    const updateDBCartItemQty = (item: any, timeout = 1000) => {
      if (serverReqFlag) clearTimeout(serverReqFlag);
      serverReqFlag = setTimeout(() => {
        if (userLoggedIn.value == true) {
          CartService.updateUserCartItem(item.id, item.quantity);
        }
      }, timeout);
    }
    const incrementCartItemQty = (item: any) => {
      item.quantity++;
      updateDBCartItemQty(item);
    }
    const decrementCartItemQty = (item: any) => {
      const minQty = getProductMinOrderQty(item.productId) || 1;
      if (item.quantity > minQty) {
        item.quantity--;
        updateDBCartItemQty(item);
      }
    }
    const onFinishInputCartItemQty = (item: any, e: any) => {
      const minQty = Number(getProductMinOrderQty(item.productId) || 0);
      const inputQty = Number(e.target.value);
      item.quantity = inputQty > minQty ? Math.ceil(+inputQty) : minQty;
      item.quantity++;
      item.quantity--; // trigger UI updates
      updateDBCartItemQty(item);
    }
    const removeCartItem = async (item: any) => {
      const alert = await alertController.create({
        header: t('CartPage.removeCartItem'),
        message: t('CartPage.confirmRemoveCartItem'),
        buttons: [
          {
            text: t('cancel'),
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: t('yes'),
            handler: () => {
              item.quantity = 0;
              const idx = cartItems.value.findIndex(cartItem => cartItem.id == item.id);
              cartItems.value.splice(idx, 1);
              updateDBCartItemQty(item, 0);
            }
          }
        ]
      });
      return alert.present();
    }
    const openCartItemModal = async (item: any) => {
      const modal = await modalController.create({
        component: CartItemModal,
        componentProps: { productId: item.productId, editingCartItem: item },
      });
      return modal.present();
    };
    const getCartTotal = () => (cartItems.value.reduce((a: any, b: any) => +a + (b.unitPrice * b.quantity), 0));

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, remove, createOutline, trashOutline, searchOutline, notificationsOutline,

      // variables
      loading, cartItems, groupedCartItems,
      addResizeUrlParams,

      // methods
      t, incrementCartItemQty, decrementCartItemQty, onFinishInputCartItemQty,
      openCartItemModal, removeCartItem,
      getCartTotal, formatDate,
      getProductKeyVal, getProductMinOrderQty,
    };
  },
};
